<template>
    <!-- Modal Feedback -->
    <div class="modal fade" id="modal-feedback" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" @hide="onHideModal">
      <div class="modal-dialog" style="max-width:640px;">
          <div class="modal-content relative">
              <div class="confirm-feedback">
                <h2>{{$t('Need help ?')}}</h2>
                <p>{{$t('Send us a message on any information you may need')}}</p>          
                <AlertBox v-if="res_feedback && feedback_msg()" :message="$t(feedback_msg())" :variant="sendFeedbackSuccess() ? 'success' : 'danger'"/>
                <div v-if="empty_text" class="alert alert-danger" style=" text-align: left">{{empty_text}}</div>
                <div class="box-btn-menu" style="margin: 0px">                      
                    <textarea id="problem_text" v-model="inputFeedback" ></textarea>   
                    <button data-description="ICON Cancel feedback" data-bs-dismiss="modal" aria-label="Close" class="cancel btn btn-border-orange"> {{ $t("Cancel") }}</button>
                    <button data-description="ICON Send feedback" @click="sendFeedback()" class="send btn btn-orange">{{$t('Send')}}</button> 
                </div>
              </div>     
              <div v-if="loadingSendFeedback" class="area-overlay">
                  <div class="loadding_circle"></div>
              </div> 
          </div>
      </div>
    </div>
</template>

<script>
import AlertBox from '../../components/Layout/AlertBox.vue'
export default {
    name : "ModalFeedback",
    components: {    
        AlertBox
    },
    data() {
        return {
            inputFeedback : "",
            res_feedback : false,
            empty_text : "",
        }
    },
    methods : {
        sendFeedback() {     
            if(this.inputFeedback === ""){
                this.empty_text = this.$t("blank_text")            
                return false
            }        
            this.$store.dispatch("feedback/send_feedback", {  link : location.href, message : this.inputFeedback})   
            this.res_feedback = true    
        },
        sendFeedbackSuccess() {
            return this.$store.getters["feedback/sendFeedbackSuccess"]
        },
        feedback_msg(){
            return this.$store.getters["feedback/feedback_msg"]
        },
    },
    computed : {
        loadingSendFeedback() {
            return this.$store.getters["feedback/load"];
        }
    },
    unmounted(){
        this.inputFeedback = ""
        this.res_feedback = false
        this.empty_text = ""
    }
}
</script>

<style scoped>
#modal-feedback .confirm-feedback textarea {
    width: 100%;
    height: 228px;
    margin-bottom: 40px;
    padding: 10px;
    border: none;
    background-color: #efefef;
}  
</style>