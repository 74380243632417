<template>   
   <HeaderCommon> 
        <template v-slot:list-menu>
          <li>
            <router-link to="/" @click="handleClickMenuAndBoxSearchHiden">    
              <img src="../../prismaccess-common/src/assets/images/icon-menu/ic-profil-logout-off.svg" alt="Sign out" />            
              <p>Home Page</p>
            </router-link>
          </li>
          <li>
            <router-link to="/test" @click="handleClickMenuAndBoxSearchHiden">    
              <img src="../../prismaccess-common/src/assets/images/icon-menu/ic-profil-logout-off.svg" alt="Sign out" />            
              <p>Test Page</p>
            </router-link>
          </li>
        </template>
    </HeaderCommon>  
</template>
<script>
import { useStore } from 'vuex'
import HeaderCommon from "../../prismaccess-common/src/components/Layout/Header.vue";
 
export default {
  components : {
    HeaderCommon
  },
  setup(){
      const store = useStore()
      const handleClickMenuAndBoxSearchHiden = () => {
          store.dispatch("header/setOpenMenu", false);
          store.dispatch("header/setOpenBoxSearch", false);
      }
      return { handleClickMenuAndBoxSearchHiden }
  }
};
</script>

<style scoped> 
</style>