<template>
  <header>
      <nav :class="'navbar navbar-expand navbar-light bg-white shadow-sm navs-sm fixed-top' + (isUserFrench ? ' page-french' : '')" aria-label="Second navbar example" style="z-index: 1030;" id="header">
        <div class="container-fluid padding0">
          <router-link :to="homePage()" class="navbar-brand">
            <img src="../../assets/images/logo.svg" alt="" />
          </router-link> 

          <div class="collapse navbar-collapse" data-html2canvas-ignore="true">
             <ul class="navbar-nav me-auto"></ul>
            <form v-if="isLogin()" class="form-inline-cs" >
              <a :class="(isUserFrench ? 'btn-border-blue btn-blue ' : 'btn-orange ') + 'text-center me-3'" data-bs-toggle="modal" data-bs-target="#modal-feedback"
                       style="cursor: pointer; width: max-content !important;">Feedback</a>                
              
              <slot name="slot-icon"></slot>
              <div
                :class="'icon-user ' + (openMenu ? 'active' : '')"
                v-if="headerType !== 'login'"
                @click="handleClickMenu"
              ></div>
            </form>
             
          </div>
        </div>
      </nav>

      
      <div :class="'my-space ' + (openMenu ? 'open-bar' : '')">
        <div class="box-spacing">
        <ul>
          <li class="title-my-space">
            <p>
              <strong>{{ $t("My space") }}</strong>
            </p>
          </li>
          <li class="li-language" @click="openLang = !openLang">
            <div id="lang">
              <img src="../../assets/images/icon-menu/ic-profil-language-off.svg" alt="Language" />
              <p>{{ $t("Language") }}</p>
            </div>
            <div :class="'bg-lang ' + (openLang ? 'active' : '')"></div>
            <div :class="'list-lang ' + (openLang ? 'active' : '')">
              <div class="close-lang">
                <img src="../../assets/images/ic-close-orange.svg" alt="close-lang" />
              </div>
              <ul class="lang">
                <li
                  :class="$i18n.locale == lang ? 'lang-active' : ''"
                  v-for="(lang, key) in languages"
                  :key="key"
                  @click="setlang(lang)"
                >
                  {{ lang }}
                </li>
              </ul>
            </div>
          </li>    
          <slot name="list-menu"></slot>
          <li data-bs-toggle="modal" data-bs-target="#modalConfirmLogout">
            <img src="../../assets/images/icon-menu/ic-profil-logout-off.svg" alt="Sign out" />
            <p>{{ $t("Sign out") }}</p>
          </li>
        </ul>
      </div>
    </div>
    <span
      :class="'bg-space' + (openMenu || openBoxSearch ? ' open-bar' : '')"
      @click="handleClickMenuAndBoxSearchHiden"
    ></span>

    <!-- Modal Déconnexion Logout-->
    <div class="modal fade" ref="confirmlogout" id="modalConfirmLogout" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="confirm-feedback">
                  <h2>{{ $t("Log out of my account.") }}</h2>
                  <span>{{ $t("You will disconnect. Are you sure?") }}</span>
                  <div class="box-area">
                      <div class="box-btn-menu">
                          <button class="cancel btn btn-border-orange"  data-bs-dismiss="modal"> {{ $t("Cancel") }}</button>
                          <button class="send btn btn-orange"  @click="logout()">  {{ $t("Confirm") }} </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <ModalFeedback/>

  </header>
</template>
<script>
import ModalFeedback from '../../components/elements/modal_feedback.vue'
import { Modal } from 'bootstrap'
import { homePage, isAdmin, isUserFrench, isLogin, isUserInter } from "../../utils";
export default {
  name: "Header",
  components : {
    ModalFeedback
  },
  data() {
    return {
      openLang: false,
      languages: ["fr", "en", "de"],
      modal : null
    };
  },
  methods: {
    homePage,
    isAdmin,
    isLogin,
    setlang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      window.location.reload();
    },
    handleClickMenu() {
      this.$store.dispatch("header/setOpenMenu", !this.openMenu);
    },
    handleClickMenuAndBoxSearchHiden() {
      this.$store.dispatch("header/setOpenMenu", false);
      this.$store.dispatch("header/setOpenBoxSearch", false);
    },     
    logout() {
      console.log('logout');
      this.handleClickMenuAndBoxSearchHiden();
      this.modal.hide();
      this.$store.dispatch("auth/logout").then(
        () => {
          this.$router.push("/login")
        }
      );
    },
  },
  props: ["headerType"],
  computed: {
    isUserFrench,
    isUserInter,
    openMenu() {
      return this.$store.getters["header/openMenu"];
    },
    openBoxSearch() {
      return this.$store.getters["header/openBoxSearch"];
    }
  },
  mounted() { 
    this.modal = new Modal(document.getElementById('modalConfirmLogout'));
    if (localStorage.getItem("language") !== this.$i18n.locale) {
      window.location.reload();
    }
  }
  
};
</script>

<style scoped>
#modal-feedback .confirm-feedback textarea {
    width: 100%;
    height: 228px;
    margin-bottom: 40px;
    padding: 10px;
    border: none;
    background-color: #efefef;
}  
</style>