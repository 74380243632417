<template>
    <Header />
    <div class="container-fluid" >
        <div class="wrapper-box wrapper-box-keyword" style="min-height: 583px;">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12"><h1 class="title-researchs">Test Page</h1></div>                                    
                
                    
            </div>
        </div>
    </div>
    <Footer />
</template>

<script>
import Header from "../components/Layout/Header.vue";
import Footer from "../prismaccess-common/src/components/Layout/Footer.vue";
export default {
    components:{
        Header,
        Footer
    }
}
</script>

<style>

</style>